import { EventAggregator } from 'aurelia-event-aggregator';
import { Tasks } from 'services/tasks';
import { Notifier } from 'common/ui';
import { Security } from 'common/security';
import { TaskTypeBase } from './../task-type-base';
import { c } from 'common/common';
import moment from 'moment';

export class PolicyChargedBack extends TaskTypeBase {
    static inject = [Element, EventAggregator, Tasks, Notifier, Security];
    _ea;
    _security;
    task;
    working = false;
    canceledDate;

    constructor(element, ea, tasks, notifier, security) {
        super(element, tasks, notifier);
        this._ea = ea;
        this._security = security;
    }

    activate(model) {
        this._activate(model);
    }

    openPolicy() {
        this._ea.publish(c.EventKeys.site.openPolicy, { policyId: this.metadata.policyId });
    }

    async submit() {
        try {
            this.working = true;
            if (this.canceledDate) this.metadata.canceledDate = moment(this.canceledDate).utcOffset(0, true).toISOString();
            await this._complete(this.metadata);
        } catch (err) {
            console.log(err);
        } finally {
            this.working = false;
        }
    }
}
