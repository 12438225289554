import { PLATFORM } from 'aurelia-pal';
import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { Page, Notifier } from 'common/ui';
import { Security } from 'common/security';
import { I18n } from 'common/i18n';
import { UiWidgets } from 'services/ui-widgets';
import { TrainingEditor } from './dialog/training-editor';
import { CourseEditor } from './dialog/course-editor';
import { TrainingTopicEditor } from './dialog/training-topic-editor';
import { DialogService } from 'aurelia-dialog';
import { ROLE } from 'common/constants';
PLATFORM.moduleName('./dialog/training-editor');
PLATFORM.moduleName('./dialog/course-editor');
PLATFORM.moduleName('./dialog/training-topic-editor');

@inject(EventAggregator, Api, Page, Notifier, Security, I18n, UiWidgets, DialogService)
export class Training {

    showVideoUrl = '';
    trainings = [];
    topics = [];

    constructor(eventAggregator, api, page, notifier, security, i18n, uiWidgets, dialogService) {
        this.eventAggregator = eventAggregator;
        this.api = api;
        this.page = page;
        this.notifier = notifier;
        this.security = security;
        this.i18n = i18n;
        this.uiWidgets = uiWidgets;
        this._dialogService = dialogService;

        this.isAdmin = this.security.isInRole([ROLE.Admin, ROLE.CarrierAdmin, ROLE.ResourceAdmin, ROLE.TrainingAdmin]);
    }

    activate() {
        this.page.track('members/resources');
        this._initialize(false);
        this._loadTopics();
    }

    attached() {
        $("#training-video-dialog").on("hidden.bs.modal", this.stopVideo);
        this._initializeHandler = this.eventAggregator.subscribe('lpfn.training.initialize', (uiWidget) => {
            this._initialize(true);
        });
        this._topicHandler = this.eventAggregator.subscribe('lpfn.training.topic.initialize', (uiWidget) => {
            this.uiWidgets.addToCache(uiWidget);
            this._loadTopics();
        });
    }

    detached() {
        this._initializeHandler.dispose();
        this._topicHandler.dispose();
    }

    _initialize(clear) {
        if (clear) this.trainings = [];
        this._loadIntros();
    }

    async _loadIntros() {
        try {
            if (this.trainings && this.trainings.length > 0) return;
            this.trainings = await this.api.getData('training/all/' + this.i18n.currentLocale)
        } catch (err) {
            console.log(err);
        }
    }

    openTrainingEditor(training) {
        let defaultOrdering = 1;
        if (training) {
            defaultOrdering = training.ordering;
        } else if (this.trainings && this.trainings.length) {
            defaultOrdering = this.trainings[this.trainings.length - 1].ordering + 1;
        }
        var model = { 
            ordering: defaultOrdering,
            training: training
        };
        this._dialogService.open({ viewModel: TrainingEditor, model: model, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
            if (!response.output) {
                this._initialize(true);
                return;
            }
            if (!training || response.output.slug === training.slug) {
                this._initialize(true);
                return;
            }
            this.uiWidgets.initialize().then(() => {
                this._initialize(true);
            });
        });
    }

    openTrainingCourseEditor(training, course) {
        var model = {
            trainingId: training.id,
            restrictByCarrier: training.restrictByCarrier,
            course: course,
            ordering: course ? course.ordering : training.courses.length ? training.courses[training.courses.length-1].ordering + 1 : 1
        };
        this._dialogService.open({ viewModel: CourseEditor, model: model, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
            if (!response.output) {
                this._initialize(true);
                return;
            }
            if (!course || (response.output.slug === course.slug && response.output.trainingId === training.id)) {
                this._initialize(true);
                return;
            }
            this.uiWidgets.initialize().then(() => {
                this._initialize(true);
            });
        });
    }

    playVideo(videoUrl) {
        $("#embedded-video").attr("src", videoUrl);
    }
    stopVideo() {
        $("#embedded-video").attr("src", "about:blank");
    }
    showPowerpoint(pptUrl) {
        $('#embedded-powerpoint').attr('src', pptUrl);
    }

    async _loadTopics() {
        this.topics = [];
        try {
            this.topics = await this.api.getData('training/topic/' + this.i18n.currentLocale);
        } catch (err) {
            console.log(err);
        }
    }

    async openTopicEditor(topic) {
        this._dialogService.open({ viewModel: TrainingTopicEditor, model: topic || null, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
            if (response.output.deleted) this._initialize(true);
            this._loadTopics();
        });
    }
}
