export class TrainingModule {
	index = 0;
	id = '';
	name = '';
	html = '';
	moduleType = 'Module';
	quizId = '';

	constructor(index, id, name) {
		this.index = index;
		this.id = id + '';
		this.name = name;
	}

	addHtml(html) {
		this.html = html;
	}

	setQuizId(quizId) {
		this.moduleType = 'Quiz';
		this.quizId = quizId;		
	}
	
	setQuizId2(quizId) {
		this.moduleType = 'Quiz2';
		this.quizId = quizId;
	}
}
