import { Page } from 'common/ui';

export class PrivacyPolicy {
	static inject = [Page];

	constructor(page) {
		this.page = page;
	}

	activate() {
		this.page.track('terms-of-use');
	}

	attached() {
	    this.page.scrollToTop();
	}
} 
