import { PLATFORM } from 'aurelia-pal';

export class Index {
    constructor() {
    }

    configureRouter(config, router) {
        config.map([
            {
                route: ['', 'task/:id'],
                moduleId: PLATFORM.moduleName('members/tasks/list'),
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: false },
            },
        ]);

        this.router = router;
    }
}
