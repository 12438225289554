import { bindable } from 'aurelia-framework';
import { Security } from 'common/security';
import { Sso } from 'services/sso';

export class Surancebay {
    static inject = [Security, Sso];
    @bindable module;
    @bindable view;
    @bindable newWindow = true;
    @bindable ssoOnLoad = true;

    _security;
    _sso;

    model;
    ssoFormEl;

    constructor(security, sso) {
        this.security = security;
        this._sso = sso;
    }

    activate(model) {
        this.memberId = this.security.authenticatedMemberId;
        if (!model) return;
        if (model.newWindow) this.newWindow = model.newWindow === 'true' ? true : false;
        if (model.view) this.view = model.view;
    }

    async attached() {
        try {
            if (this.security.isShadow) return;
            this.model = await this._sso.suranceBay(this.module, this.view, this.newWindow);
            this.loaded = true;
        } catch (err) {
            console.log(err);
        }
    }

    submitSso() {
        this.ssoFormEl.submit();
    }
}
