import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';
import {I18n} from 'common/i18n';
import {Api} from 'common/server';
import {Notifier} from 'common/ui';
import {c} from 'common/common';

@inject(DialogController, NewInstance.of(ValidationController), I18n, Api, Notifier)
export class TrainingEditor {

    id = null;
    slug = '';
    ordering = 1;
    isActive = false;
    restrictByCarrier = false;
    showDeleteConfirm = false;
    minimumLevel = null;
    levels = [];

    constructor(dialogController, validationController, i18n, api, notifier) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._i18n = i18n;
        this._api = api;
        this._notifier = notifier;

        this.deleteText = this._i18n.tr('delete');

        ValidationRules
            .ensure('slug').required().matches(c.RegEx.slug)
            .ensure('ordering').required().satisfiesRule('integerRange', 1, 5000)
            .on(this);
    }

    async activate(model) {
        await this._loadLevels();
        this.id = null;
        this.slug = '';
        this.ordering = model.ordering || 1;
        this.isActive = false;
        this.restrictByCarrier = false;
        this.title = this._i18n.tr('training-add-title');
        this.minimumLevel = '';
        if (model.training) {
            this.id = model.training.id;
            this.slug = model.training.slug;
            this.ordering = model.training.ordering;
            this.isActive = model.training.isActive;
            this.restrictByCarrier = model.training.restrictByCarrier;
            this.minimumLevel = model.training.minimumLevel;
            this.title = this._i18n.tr('training-edit-title');
        }
        this.validationController.reset();
    }

    async save() {
        const v = await this.validationController.validate();
        if (!v.valid) return;

        try {
            if (this.minimumLevel === 'null') this.minimumLevel = null;
            var saveModel = { slug: this.slug, ordering: this.ordering, isActive: this.isActive, restrictByCarrier: this.restrictByCarrier, minimumLevel: this.minimumLevel };

            if (this.id) {
                await this._api.updateData('training/' + this.id, saveModel);
                this._notifier.success('training-update-successful', { slug: this.slug });
                this.dialogController.ok({ id: this.id, slug: this.slug, ordering: this.ordering });
            } else {
                const data = await this._api.postData('training/', saveModel);
                this._notifier.success('training-add-successful', { slug: this.slug });
                this.dialogController.ok({ id: data.id, slug: this.slug, ordering: this.ordering });
            }
        } catch (err) {
            if (err) this._notifier.error(err);
            else this._notifier.generalError();
        }
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this._i18n.tr('delete');
    }

    async deleteTraining() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this._i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;

        try {
            await this._api.deleteData('training/' + this.id);
            this._notifier.success('training-delete-successful', { slug: this.slug });
            this.dialogController.ok();
        } catch (err) {
            this._notifier.generalError();
        }
    }

    async _loadLevels() {
        try {
            this.levels = await this._api.get('level/list-all');
        } catch(error) {
            console.log(error);
        }
    }
}
