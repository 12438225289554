import { Tasks } from 'services/tasks';
import { Notifier } from 'common/ui';
import { Security } from 'common/security';
import { Levels } from 'services/levels';
import { TaskTypeBase } from './../task-type-base';

export class NewAgentLevelRequest extends TaskTypeBase {
    static inject = [Element, Tasks, Notifier, Security, Levels];
    _security;
    _levels;
    task;
    working = false;

    constructor(element, tasks, notifier, security, levels) {
        super(element, tasks, notifier);
        this._security = security;
        this._levels = levels;
    }

    activate(model) {
        this._activate(model);
    }

    async attached() {
        try {
            const levels = await this._levels.byTypeRestricted(this._security.authenticatedMemberId);
            if (levels && levels.lifeLevels && levels.lifeLevels.length) {
                const L00Index = levels.lifeLevels.findIndex(x => x === 'L00');
                if (L00Index >= 0) levels.lifeLevels.splice(L00Index, 1);
            }
            this.levels = levels;
        } catch (err) {
            console.log(err);
        }
    }

    async submit() {
        try {
            this.working = true;
            await this._complete(this.metadata);
        } catch (err) {
            console.log(err);
        } finally {
            this.working = false;
        }
    }
}
