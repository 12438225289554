import { EventAggregator } from 'aurelia-event-aggregator';
import { Tasks } from 'services/tasks';
import { Notifier } from 'common/ui';
import { Security } from 'common/security';
import { Nylas } from 'services/nylas';
import { TaskTypeBase } from './../task-type-base';

export class ReauthNylas extends TaskTypeBase {
    static inject = [Element, EventAggregator, Tasks, Notifier, Security, Nylas];
    _ea;
    security;
    _nylas;
    task;
    working = false;
    canceledDate;

    constructor(element, ea, tasks, notifier, security, nylas) {
        super(element, tasks, notifier);
        this._ea = ea;
        this.security = security;
        this._nylas = nylas;
    }

    async activate(model) {
        this._activate(model);
        this.mustReauth = await this._nylas.mustReauth();
        this._loadNylasAuthUrl();
    }

    async _loadNylasAuthUrl() {
        try {
            this.nylasAuthUrl = await this._nylas.redirectToAuthUrl(this.metadata.email, 'task');
        } catch (err) {
            console.log(err);
        }
    }

    async complete() {
        try {
            this.working = true;
            await this._complete(this.metadata);
        } catch (err) {
            console.log(err);
        } finally {
            this.working = false;
        }
    }
}
