export class TaskTypeBase {
    _element;
    _tasks;
    _notifier;

    constructor(element, tasks, notifier) {
        this._element = element;
        this._tasks = tasks;
        this._notifier = notifier;
    }

    _activate(model) {
        this.id = model.id;
        this.taskType = model.taskType;
        this.metadata = model.metadataJson ? JSON.parse(model.metadataJson) : {};
        this.assignedToMember = model.assignedToMember;
        this.aboutMember = model.aboutMember;
        this.aboutMemberDirectUpline = model.aboutMemberDirectUpline;
        this.aboutMemberAgency = model.aboutMemberAgency;
        this.createdByMember = model.createdByMember;
    }

    async _complete(model) {
        try {
            await this._tasks.complete(this.id, model);
            this._dispatchEvent();
        } catch (err) {
            this._notifier.error(err);
        }
    }

    async _save(model) {
        try {
            await this._tasks.save(this.id, model);
            this._notifier.success('task-saved');
        } catch (err) {
            this._notifier.error(err);
        }
    }

    _dispatchEvent() {
        this._element.dispatchEvent(new CustomEvent('completed', { bubbles: true, detail: {} }));
    }
}
