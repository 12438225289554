import { activationStrategy } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToDos, toDoType } from 'services/to-dos';
import { ToDoTypeValueConverter } from 'resources/value-converters/to-do-type';
import { c } from 'common/common';

export class List {
    static inject = [EventAggregator, ToDos, ToDoTypeValueConverter];
    _ea;
    _toDos;
    _tdtvc;

    toDos;

    filters = [
        { key: 'search', value: '', keys: ['aboutName', 'aboutTypeName', 'title', 'comments'] },
    ];
    filterColumns;

    _handlers = [];

    constructor(ea, toDos, tdtvc) {
        this._ea = ea;
        this._toDos = toDos;
        this._tdtvc = tdtvc;
    }

    determineActivationStrategy() {
        return activationStrategy.invokeLifecycle;
    }

    activate(model) {
        this.toDoId = model && model.id ? model.id : null;
        if (this._attached) this._load();
    }

    attached() {
        this._attached = true;
        this._handlers.push(this._ea.subscribe(c.EventKeys.toDos.todayUpdated, () => this._load()));
        this._load();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    _load() {
        try {
            this.working = true;
            this.toDo = undefined;
            this.toDos = this._toDos.today;
            this.toDos.forEach(td => td.aboutTypeName = this._tdtvc.toView(td.aboutType));
            if (this.toDoId) {
                this.toDo = this.toDos.find(x => x.id === this.toDoId);
            }
            this._initializeFilters();
        } catch (err) {
            this.toDos = [];
        } finally {
            this.working = false;
        }
    }

    _initializeFilters() {
        try {
            const filterColumns = [];
            const allTypes = toDoType.all().map(x => this._tdtvc.toView(x));
            filterColumns.push({ key: 'aboutTypeName', labelKey: 'to-do-type', type: 'options', options: allTypes, defaultValue: allTypes.join('|') });
            filterColumns.push({ key: 'dueDate', labelKey: 'due-date', type: 'date', initializeValue: undefined, defaultValue: undefined });
            this.filterColumns = filterColumns;
        } catch (err) {
            console.log(err);
        }
    }

    filterColumnsSet(filters) {
        if (!filters) return;
        const newFilters = [];
        this.filters.forEach(f => newFilters.push(f));
        filters.forEach(f => newFilters.push(f));
        this.filters = newFilters;
    }

    toggleFilters() {
        this.showDrawer = !this.showDrawer;
    }

    gridFiltered(data) {
        this.filtered = data.filtered;
        this.filteredBy = data.filteredBy;
    }

    removeFilter(filter) {
        this.removeAdvancedFilter = filter;
    }
}
