import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';
import {I18n} from 'common/i18n';
import {Api} from 'common/server';
import {Notifier} from 'common/ui';
import {c} from 'common/common';

@inject(DialogController, NewInstance.of(ValidationController), I18n, Api, Notifier)
export class TrainingTopicEditor {

    id = null;
    slug = '';
    isActive = false;
    showDeleteConfirm = false;
    minimumLevel = null;

    levels;

    constructor(dialogController, validationController, i18n, api, notifier) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._i18n = i18n;
        this._api = api;
        this._notifier = notifier;

        this.deleteText = this._i18n.tr('delete');

        ValidationRules
            .ensure('slug').required().matches(c.RegEx.slug)
            .on(this);
    }

    async activate(model) {
        await this._loadLevels();
        this.id = null;
        this.slug = '';
        this.isActive = false;
        this.minimumLevel = null;
        this.title = this._i18n.tr('training-topic-add-title');
        if (model) {
            this.title = this._i18n.tr('training-topic-edit-title');
            this.id = model.id;
            this.slug = model.slug;
            this.isActive = model.isActive;
            this.minimumLevel = model.minimumLevel;
        }
        this.validationController.reset();
    }

    save() {
        var me = this;
        this.validationController.validate().then(v => {
            if (!v.valid) return;
            if (me.minimumLevel === 'null') me.minimumLevel = null;

            var saveModel = { slug: me.slug, isActive: me.isActive, minimumLevel: me.minimumLevel };

            if (me.id) {
                me._api.updateData("training/topic/" + me.id, saveModel).then(data => {
                    me._notifier.success('training-topic-update-successful', { slug: me.slug });
                    me.dialogController.ok({ deleted: false, id: me.id, slug: me.slug });
                }).catch(error => {
                    if (error) me._notifier.error(error);
                    else me._notifier.generalError();
                });
            } else {
                me._api.postData("training/topic", saveModel).then(data => {
                    me._notifier.success('training-topic-add-successful', { slug: me.slug });
                    me.dialogController.ok({ deleted: false, id: data.id, slug: me.slug });
                }).catch(error => {
                    if (error) me._notifier.error(error);
                    else me._notifier.generalError();
                });
            }
        });
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this._i18n.tr('delete');
    }

    deleteTrainingTopic() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this._i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;
        var me = this;

        me._api.deleteData("training/topic/" + me.id).then(data => {
            me._notifier.success('training-topic-delete-successful', { slug: me.slug });
            me.dialogController.ok({ deleted: true });
        }).catch(error => {
            me._notifier.generalError();
        });
    }

    async _loadLevels() {
        try {
            this.levels = await this._api.getData('level/list-all');
        } catch (err) {
            console.log(err);
        }
    }
}
