import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';
import {I18n} from 'common/i18n';
import {Api} from 'common/server';
import {Notifier} from 'common/ui';
import {c} from 'common/common';

@inject(DialogController, NewInstance.of(ValidationController), I18n, Api, Notifier)
export class CourseModuleEditor {

    id = null;
    courseId = null;
    slug = '';
    ordering = 1;
    isActive = false;
    showDeleteConfirm = false;

    moduleTypes = [];
    selectedModuleTypeId = 'standard';

    constructor(dialogController, validationController, i18n, api, notifier) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this.i18n = i18n;
        this.api = api;
        this.notifier = notifier;

        this.deleteText = this.i18n.tr('delete');

        this.moduleTypes.push({ id: 'standard', name: this.i18n.tr('training-courses-module-types-standard') });
        this.moduleTypes.push({ id: 'quiz', name: this.i18n.tr('training-courses-module-types-quiz') });

        ValidationRules
            .ensure('slug').required().matches(c.RegEx.slug)
            .ensure('ordering').required().satisfiesRule('integerRange', 1, 5000)
            .on(this);
    }

    activate(model) {
        this.id = null;
        this.slug = '';
        this.ordering = model.ordering || 1;
        this.isActive = false;
        this.title = this.i18n.tr('training-courses-module-add-title');
        this.courseId = model.courseId;
        if (model.module) {
            this.title = this.i18n.tr('training-courses-module-edit-title');
            this.id = model.module.id;
            this.slug = model.module.slug;
            this.ordering = model.module.ordering;
            this.isActive = model.module.isActive;
            this.quizId = model.module.quizId;
            this.selectedModuleTypeId = model.module.moduleType;
        }
        this.validationController.reset();
    }

    moduleTypeChanged() {
        this.quizId = '';
        return true;
    }

    save() {
        var me = this;
        this.validationController.validate().then(v => {
            if (!v.valid) return;

            var saveModel = { slug: me.slug, ordering: me.ordering, isActive: me.isActive, quizId: me.quizId };

            if (me.id) {
                me.api.updateData("training/course/" + me.courseId + "/module/" + me.id, saveModel).then(data => {
                    me.notifier.success('training-courses-module-update-successful', { slug: me.slug });
                    me.dialogController.ok({ id: me.id, slug: me.slug, ordering: me.ordering });
                }).catch(error => {
                    if (error) me.notifier.error(error);
                    else me.notifier.generalError();
                });
            } else {
                me.api.postData("training/course/" + me.courseId + "/module", saveModel).then(data => {
                    me.notifier.success('training-courses-module-add-successful', { slug: me.slug });
                    me.dialogController.ok({ id: data.id, slug: me.slug, ordering: me.ordering });
                }).catch(error => {
                    if (error) me.notifier.error(error);
                    else me.notifier.generalError();
                });
            }
        });
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this.i18n.tr('delete');
    }

    deleteModule() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this.i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;
        var me = this;

        me.api.deleteData("training/course/" + me.courseId + "/module/" + me.id).then(data => {
            me.notifier.success('training-courses-module-delete-successful', { slug: me.slug });
            me.dialogController.ok();
        }).catch(error => {
            me.notifier.generalError();
        });
    }
}
