import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { I18n } from 'common/i18n';
import { Api } from 'common/server';
import { Notifier } from 'common/ui';
import { c } from 'common/common';
import { Content } from 'common/content';

@inject(DialogController, NewInstance.of(ValidationController), I18n, Api, Notifier, Content)
export class CourseEditor {
    _content;

    id = null;
    trainingId = null;
    newTrainingId = null;
    slug = '';
    ordering = 1;
    isActive = false;
    showDeleteConfirm = false;
    restrictByCarrier = false;

    groupByOption = null;
    carriers = [];
    carrierId = null;
    trainingTopics = [];
    trainingTopicId = null;

    courseTypes = [];
    selectedCourseTypeId = 'online';

    showUrl = false;
    urlLabel = '';
    url = '';
    urlCaption = '';
    showUrl2 = false;
    url2 = '';
    url2Label = '';
    url2Caption = '';
    contentAfterLink = false;

    constructor(dialogController, validationController, i18n, api, notifier, content) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this.i18n = i18n;
        this.api = api;
        this.notifier = notifier;
        this._content = content;

        this.deleteText = this.i18n.tr('delete');

        this.courseTypes.push({ id: 'online', name: this.i18n.tr('training-courses-types-online') });
        this.courseTypes.push({ id: 'internal-web', name: this.i18n.tr('training-courses-types-internal-web') });
        this.courseTypes.push({ id: 'video', name: this.i18n.tr('training-courses-types-video') });
        this.courseTypes.push({ id: 'document', name: this.i18n.tr('training-courses-types-document') });
        this.courseTypes.push({ id: 'external-web', name: this.i18n.tr('training-courses-types-external-web') });
        this.courseTypes.push({ id: 'powerpoint-online', name: this.i18n.tr('training-courses-types-powerpoint-online') });

        ValidationRules
            .ensure('slug').required().matches(c.RegEx.slug)
            .ensure('ordering').required().satisfiesRule('integerRange', 1, 5000)
            .on(this);
    }

    async activate(model) {
        await this._loadTrainings();
        await this._loadCarriers();
        await this._loadTopics();
        this.id = null;
        this.slug = '';
        this.ordering = model.ordering || 1;
        this.isActive = false;
        this.carrierId = null;
        this.trainingTopicId = null;
        this.groupByOption = null;
        this.title = this.i18n.tr('training-courses-add-title');
        this.trainingId = model.trainingId;
        this.newTrainingId = this.trainingId;
        this.restrictByCarrier = model.restrictByCarrier;
        if (model.course) {
            this.title = this.i18n.tr('training-courses-edit-title');
            this.id = model.course.id;
            this.slug = model.course.slug;
            this.ordering = model.course.ordering;
            this.selectedCourseTypeId = model.course.type;
            this.url = model.course.url;
            this.url2 = model.course.url2;
            this.contentAfterLink = model.course.contentAfterLink;
            this.carrierId = model.course.carrierId;
            this.trainingTopicId = model.course.trainingTopicId;
            this.isActive = model.course.isActive;

            if (this.carrierId) this.groupByOption = 'carrier';
            if (this.trainingTopicId) this.groupByOption = 'topic';
        }
        this.validationController.reset();
        this._setInputsByType();
    }

    courseTypeChanged() {
        this.url = '';
        this.url2 = '';
        this._setInputsByType();
        return true;
    }

    _setInputsByType() {
        this.showUrl = false;
        this.showUrlFileIcon = false;
        this.showUrl2 = false;
        this.urlCaption = '';
        this.url2Caption = '';
        switch (this.selectedCourseTypeId) {
            case 'online':
                break;
            case 'internal-web':
                break;
            case 'video':
                this.urlLabel = this.i18n.tr('training-courses-types-video-url-label');
                this.urlCaption = this.i18n.tr('training-courses-types-video-url-caption');
                this.showUrl = true;
                this.showUrl2 = true;
                this.url2Label = this.i18n.tr('training-courses-audio-url');
                this.url2Caption = this.i18n.tr('training-courses-audio-caption');
                break;
            case 'document':
                this.urlLabel = this.i18n.tr('training-courses-types-document-url-label');
                this.showUrl = true;
                this.showUrlFileIcon = true;
                break;
            case 'powerpoint-online':
                this.urlLabel = this.i18n.tr('training-courses-types-powerpoint-online-url-label');
                this.urlCaption = this.i18n.tr('training-courses-types-powerpoint-online-url-caption');
                this.showUrl = true;
                this.showUrl2 = true;
                this.url2Label = this.i18n.tr('training-courses-types-powerpoint-online-url2-label');
                this.url2Caption = this.i18n.tr('training-courses-types-powerpoint-online-url2-caption');
                break;
            case 'external-web':
                this.urlLabel = this.i18n.tr('training-courses-types-external-web-url-label');
                this.showUrl = true;
                break;
        }
    }

    save() {
        var me = this;
        this.validationController.validate().then(v => {
            if (!v.valid) return;

            if (me.carrierId === 'null') me.carrierId = null;

            var saveModel = { 
                slug: me.slug, 
                ordering: me.ordering,
                courseType: me.selectedCourseTypeId,
                url: me.url,
                url2: me.url2,
                contentAfterLink: me.contentAfterLink,
                isActive: me.isActive,
                carrierId: me.carrierId,
                trainingTopicId: me.trainingTopicId,
                trainingId: me.newTrainingId
            };

            if (me.id) {
                me.api.updateData("training/" + me.trainingId + "/course/" + me.id, saveModel).then(data => {
                    me.notifier.success('training-courses-update-successful', { slug: me.slug });
                    me.dialogController.ok({ id: me.id, slug: me.slug, ordering: me.ordering, trainingId: me.newTrainingId });
                }).catch(error => {
                    if (error) me.notifier.error(error);
                    else me.notifier.generalError();
                });
            } else {
                me.api.postData("training/" + me.trainingId + "/course", saveModel).then(data => {
                    me.notifier.success('training-courses-add-successful', { slug: me.slug });
                    me.dialogController.ok({ id: data.id, slug: me.slug, ordering: me.ordering, trainingId: me.newTrainingId });
                }).catch(error => {
                    if (error) me.notifier.error(error);
                    else me.notifier.generalError();
                });
            }
        });
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this.i18n.tr('delete');
    }

    deleteCourse() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this.i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;
        var me = this;

        me.api.deleteData("training/" + me.trainingId + "/course/" + me.id).then(data => {
            me.notifier.success('training-courses-delete-successful', { slug: me.slug });
            me.dialogController.ok();
        }).catch(error => {
            me.notifier.generalError();
        });
    }

    async _loadCarriers() {
        this.carriers = await this.api.getData('carrier');
    }

    async _loadTopics() {
        this.trainingTopics = await this.api.getData('training/topic/' + this.i18n.currentLocale);
    }

    async _loadTrainings() {
        this.trainings = await this.api.getData('training/list/' + this.i18n.currentLocale);
    }

    openFileManager(pathProperty, object, nameProperty) {
        this._content.fileManager(file => {
            const name = file.displayName || file.fileName;
            if (object) {
                object[pathProperty] = file.path;
                if (nameProperty) object[nameProperty] = name;
            } else {
                this[pathProperty] = file.path;
                if (nameProperty) this[nameProperty] = name;
            }
        });
    }
}
