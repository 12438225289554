import { EventAggregator } from 'aurelia-event-aggregator';
import { Tasks } from 'services/tasks';
import { Notifier } from 'common/ui';
import { Security } from 'common/security';
import { Carriers } from 'services/carriers';
import { TaskTypeBase } from './../task-type-base';

export class DataFeedException extends TaskTypeBase {
    static inject = [Element, EventAggregator, Tasks, Notifier, Security, Carriers];
    _ea;
    security;
    _carriers;
    task;
    working = false;
    canceledDate;

    constructor(element, ea, tasks, notifier, security, carriers) {
        super(element, tasks, notifier);
        this._ea = ea;
        this.security = security;
        this._carriers = carriers;
    }

    activate(model) {
        this._activate(model);
    }

    async complete() {
        try {
            this.working = true;
            await this._complete(this.metadata);
        } catch (err) {
            console.log(err);
        } finally {
            this.working = false;
        }
    }

    async downloadDataFeedFile() {
        try {
            await this._carriers.downloadImportFile(this.metadata.importFileName, this.metadata.dataFeedType, this.metadata.carrierId);
        } catch (err) {
            console.log(err);
        }
    }
}
