import { PLATFORM } from 'aurelia-pal';
import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Api} from 'common/server';
import {Page, Notifier} from 'common/ui';
import {Security} from 'common/security';
import {I18n} from 'common/i18n';
import {UiWidgets} from 'services/ui-widgets';
import {CourseModuleEditor} from './dialog/course-module-editor';
import {DialogService} from 'aurelia-dialog';
import {TrainingModule} from './courses/training-module';
import { c } from 'common/common';
import { ROLE } from 'common/constants';
import environment from '../../../config/environment.json';
PLATFORM.moduleName('./dialog/course-module-editor');

@inject(EventAggregator, Api, Page, Notifier, Security, I18n, UiWidgets, DialogService)
export class TrainingCourse {

    trainingSlug = '';
    courseSlug = '';
    moduleSlug = '';
    training = null;
    associatedCourses = [];
	course = null;
	module = null;
	isOnFirstModule = true;
	isOnLastModule = false;

	constructor(eventAggregator, api, page, notifier, security, i18n, uiWidgets, dialogService) {
	    this.eventAggregator = eventAggregator;
	    this._api = api;
	    this.page = page;
	    this.notifier = notifier;
	    this.security = security;
	    this.i18n = i18n;
	    this.uiWidgets = uiWidgets;
	    this.dialogService = dialogService;

        this.isAdmin = this.security.isInRole([ROLE.Admin, ROLE.TrainingAdmin]);
	}

	activate(params, routerConfig) {
	    this.trainingSlug = params.trainingSlug;
	    this.courseSlug = params.courseSlug || null;
	    this.moduleSlug = params.moduleSlug || null;
	    var pageUrl = 'members/resources/' + this.trainingSlug + '/' + this.courseSlug;
	    if (this.moduleSlug) this.page.track(pageUrl + '/' + this.moduleSlug);
	    else this.page.track(pageUrl);
	    this._initialize(true);
	}

	_initialize(clear) {
	    var me = this;
	    if (clear) {
	        me.training = null;
	        me.course = null;
	        me.module = null;
	        me.isOnFirstModule = true;
	        me.isOnLastModule = false;
        }
	    me._loadCourse().then(() => {
	        me._activateSlug();
	        me._scrollToModuleTitle();
	    }).catch(error => {
	        console.log('error loading course');
	    });
	}

	_loadCourse() {
	    var me = this;

	    return new Promise((resolve, reject) => {
	        if (me.course && me.course.modules && me.course.modules.length > 0) { resolve(); return; }

	        me._api.getData('training/' + this.trainingSlug + '/course/' + me.courseSlug + '/' + me.i18n.currentLocale).then(model => {
	            me.training = model.training;
	            me.course = model;
	            me.prependTitle = '';
	            if (me.course.associatedWithCarrier) me.prependTitle = me.course.carrierName + ': ';

	            me.associatedCourses = me.training.courses;
	            if (me.course.carrierId) {
	                me.associatedCourses = [];
	                for (var i = 0; i < me.training.carriers.length; i++) {
	                    if (me.training.carriers[i].id !== me.course.carrierId) continue;
	                    me.associatedCourses = me.training.carriers[i].courses;
	                    break;
	                }
	            }

	            me.module = null;
	            if (!me.course.modules || me.course.modules.length === 0) { me.course.modules = []; resolve(); return; }

	            if (!me.moduleSlug) { me.module = me.course.modules[0]; resolve(); return; }

	            for (var i = 0; i < me.course.modules.length; i++) {
	                if (me.course.modules[i].slug !== me.moduleSlug) continue;
	                me.module = this.course.modules[i];
                    break;
	            }

	            if (me.module === null) {
	                me.module = me.course.modules[0];
	            }

	            resolve();
	        }).catch(error => {
	            reject();
	        });
	    });
	}

	showModule(module) {
	    this.module = module;
	    this._activateSlug();
	    this._scrollToModuleTitle();
	}

	_activateSlug() {
	    this.isOnFirstModule = true;
	    this.isOnLastModule = false;
	    this.stopVideo();

		this.courseUrl = undefined;
	    if (!this.course) return;
		if (this.course.url) this.courseUrl = c.Helpers.replaceVariables(this.security, this.course.url, true);

	    if (this.course.type === 'video') {
			let src = this.course.url;
			if (src.indexOf('http') < 0) {
				const slash = this.course.url.indexOf('/') === 0 ? '' : '/';
				src = `${environment.static}${slash}${this.course.url}`;
			}
	        $("#embedded-video").attr("src", src);
	        $('#video-audio').html('<audio controls><source src="'+this.course.audioUrlMp3+'" type="audio/mpeg"><source src="'+this.course.audioUrlOgg+'" type="audio/ogg"><embed height="30" width="300" autostart="false" src="'+this.course.audioUrlMp3+'"></audio>');
	        return;
	    }

	    if (this.course.type === 'document') {
			let src = this.course.url;
			if (src.indexOf('http') < 0) {
				const slash = this.course.url.indexOf('/') === 0 ? '' : '/';
				src = `${environment.static}${slash}${this.course.url}`;
			}
			this.courseUrl = src;
	        $("#embedded-document").attr("src", `${src}?dispositionType=inline`);
	        return;
	    }

	    if (this.course.type === 'external-web') {
	        $("#embedded-external-web").attr("src", this.courseUrl);
	        return;
	    }

	    if (this.course.type === 'powerpoint-online') {
	        $("#embedded-powerpoint-online").attr("src", this.course.url);
	        $('#powerpoint-online-link').attr('href', this.course.url2);
	        return;
	    }

	    if (this.course.type === 'online') {

	        if (this.module) {
	            for (var i = 0; i < this.course.modules.length; i++) {
	                if (this.course.modules[i].slug !== this.module.slug) continue;
	                this.isOnFirstModule = i === 0;
	                this.isOnLastModule = i === this.course.modules.length - 1;
	                break;
	            }
	        } else {
	            this.isOnFirstModule = false;
	            this.isOnLastModule = false;
	        }

	        if (this.module && this.module.moduleType === 'quiz') {
	            var html = '<div class="quizz-container" data-width="100%" data-height="auto" data-networks="none" data-quiz="' + this.module.quizId + '"></div><script src="//dcc4iyjchzom0.cloudfront.net/widget/loader.js" async></script>';
	            $('#quiz').html(html).show();
	        } else {
	            $('#quiz').html('').hide();
	        }
	    }

	    this.page.scrollToTop();
	}

	attached() {
	    var me = this;
		me._initializeHandler = me.eventAggregator.subscribe('lpfn.training-course.initialize', function(uiWidget) {
		    me._initialize(true);
		});
    }

	detached() {
		this._initializeHandler.dispose();
    }

	previousModule() {
	    for (var i = 0; i < this.course.modules.length; i++) {
	        if (this.course.modules[i].slug !== this.module.slug) continue;
	        this.module = this.course.modules[i-1];
	        break;
	    }
	    this._activateSlug();
	    this._scrollToModuleTitle();
    }

    nextModule() {
        for (var i = 0; i < this.course.modules.length; i++) {
            if (this.course.modules[i].slug !== this.module.slug) continue;
            this.module = this.course.modules[i+1];
            break;
        }
        this._activateSlug();
        this._scrollToModuleTitle();
    }

    _scrollToModuleTitle() {
        try {
            var title = $('#module-title');
            var offsetTop = title.offset().top;
            offsetTop -= 200;
            window.scrollTo(0, offsetTop);
        } catch (error) {
            console.log(error);
        }
    }

    openModuleEditor(module) {
        var model = {
            courseId: this.course.id,
            module: module,
            ordering: module ? module.ordering : this.course.modules.length ? this.course.modules[this.course.modules.length-1].ordering + 1 : 1
        };
        this.dialogService.open({ viewModel: CourseModuleEditor, model: model, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
            if (!response.output) {
                this._initialize(true);
                return;
            }
            if (!module || response.output.slug === module.slug) {
                this._initialize(true);
                return;
            }
            this.uiWidgets.initialize().then(() => {
                this._initialize(true);
            });
        });
    }

    stopVideo() {
        $("#embedded-video").attr("src", "about:blank");
    }

    showPowerpoint(pptUrl) {
        $('#embedded-powerpoint').attr('src', pptUrl);
    }
}
