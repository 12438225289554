import { PLATFORM } from 'aurelia-pal';
import { Tasks } from 'services/tasks';
import { Notifier } from 'common/ui';
import { Security } from 'common/security';
import { DialogService } from 'aurelia-dialog';
import { TaskTypeBase } from './../task-type-base';
import moment from 'moment';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');

export class TaskLevelChangeRequest extends TaskTypeBase {
    static inject = [Element, Tasks, Notifier, Security, DialogService];
    _security;
    _dialogService;
    task;
    comments;
    commentHistory = [];
    working = false;

    constructor(element, tasks, notifier, security, dialogService) {
        super(element, tasks, notifier);
        this._security = security;
        this._dialogService = dialogService;
    }

    activate(model) {
        this._activate(model);
        this._displayApprovalComments();
    }

    _displayApprovalComments() {
        this.comments = '';
        this.commentHistory = [];
        if (typeof this.metadata.approvalComments === 'string') {
            this.comments = this.metadata.approvalComments;
        } else if (Array.isArray(this.metadata.approvalComments)) {
            this.commentHistory = this.metadata.approvalComments;
            this.commentHistory.sort((a,b) => moment(b.date).valueOf() - moment(a.date).valueOf());
        }
    }

    _setApprovalComments() {
        this.metadata.approvalComments = this.commentHistory;
        if (this.comments) {
            this.metadata.approvalComments.push({ commentByMemberId: this._security.authenticatedMemberId, commentBy: `${this._security.agent.firstName} ${this._security.agent.lastName}`, date: moment.utc().toISOString(), comment: this.comments });
            this.comments = '';
        }
    }

    async deleteComment(index) {
        this.commentHistory.splice(index, 1);
        await this.save();
    }

    confirm(approved) {
        const model = approved
            ? { key: 'approve-level-request-change', okButtonClass: 'btn-primary' }
            : { key: 'reject-level-request-change', okButtonClass: 'btn-danger' };
	    this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
            this.complete(approved);
        });
    }

    async complete(approved) {
        try {
            this.working = true;
            this.metadata.approved = approved;
            this._setApprovalComments();
            await this._complete(this.metadata);
        } catch (err) {
            console.log(err);
        } finally {
            this.working = false;
        }
    }

    async save() {
        try {
            this.working = true;
            this._setApprovalComments();
            await this._save(this.metadata);
            this._displayApprovalComments();
        } catch (err) {
            console.log(err);
        } finally {
            this.working = false;
        }
    }
}
