import { PLATFORM } from 'aurelia-pal';

export class SsoIndex {

    constructor() {
	}

	configureRouter(config, router){
		config.map([
            {
				route: 'surancebay',
				moduleId: PLATFORM.moduleName('members/sso/surancebay'),
				title: '',
				nav: false,
				settings: { canAddToMobileMenu: false }
			},
            {
				route: ['my-biz-card', 'my-biz-card/:memberId'],
				moduleId: PLATFORM.moduleName('members/sso/my-biz-card'),
				title: '',
				nav: false,
				settings: { canAddToMobileMenu: false }
			},
		]);

		this.router = router;
	}
}
