import { AgentSites, AGENT_SITE_TYPE } from 'services/agent-sites';
import { Notifier } from 'common/ui';

export class MyBizCard {
    static inject = [AgentSites, Notifier];

    _agentSites;
    _notifier;

    constructor(agentSites, notifier) {
        this._agentSites = agentSites;
        this._notifier = notifier;
    }

    async activate(model) {
        try {
            this.ssoing = true;
            const sso = await this._agentSites.ssoUrl(AGENT_SITE_TYPE.MyBizCardOnline, model.memberId);
            if (!sso || !sso.canSso) {
                this._notifier.error('mbco-sso-failed');
                return;
            }
            this.ssoUrl = sso.url;
        } catch (err) {
            console.log(err);
        } finally {
            this.ssoing = false;
        }
    }

    async attached() {
        try {
            window.location.href = this.ssoUrl;
        } catch (err) {
            console.log(err);
        }
    }
}
