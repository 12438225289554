import { TemplatingEngine } from 'aurelia-templating';
import { Menus } from 'services/menus';
import { c } from 'common/common';

export class Widget {
    static inject = [TemplatingEngine, Menus];
    _templatingEngine;
    _menus;

    widgetId;
    _widgetView;

    constructor(templatingEngine, menus) {
        this._templatingEngine = templatingEngine;
        this._menus = menus;
    }

    activate(model) {
        this.widgetId = model.id;
    }

    attached() {
        this._initialize();
    }

    detached() {
        if (this._widgetView) {
            try {
                this._widgetView.detached();
            } catch (err) {
                console.log(err);
            }
        }
    }

    async _initialize() {
        try {
            let id = c.Helpers.uniqueId();
            const mobileMenu = await this._menus.mobileMenu(this.widgetId);
            if (!mobileMenu) {
                const nonMobileSettingsValue = JSON.stringify({});
                this.widgetContainerEl.innerHTML = `<${this.widgetId} data-id="${id}" can-edit.bind="false" settings.bind="${nonMobileSettingsValue}"></${this.widgetId}>`;
            } else {
                const settings = mobileMenu.metadata ?? {};
                let settingsValue = JSON.stringify(settings);
                settingsValue = c.Helpers.replaceAll(settingsValue, '"', '\'');
                this.widgetContainerEl.innerHTML = `<${mobileMenu.code} data-id="${id}" can-edit.bind="false" settings.bind="${settingsValue}"></${mobileMenu.code}>`;
            }
            this._widgetView = this._templatingEngine.enhance({ element: this.widgetContainerEl, bindingContext: this });

        } catch (err) {
            console.log(err);
        }
    }
}